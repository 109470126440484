import { Icon } from "@/core/components/icon/Icon"
import { IFieldProps } from "@/features/fields"
import { InfoItem } from "@/shared/components/items/InfoItem"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/shared/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover"
import { cn } from "@/utils/helpers"
import { getTripletexResource } from "@/utils/tripletex"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const RESOURCE_URL = "api.tripletex.io/v2/{resource}/{id}"

const COLLECTION_MAP = {
  tripletex_customer: "customer",
  tripletex_project_manager: "employee?onlyProjectManagers=true",
  tripletex_delivery_address: "deliveryAddress",
  tripletex_department: "department",
  tripletex_main_project: "project",
  tripletex_project_category: "project-category",
  tripletex_vat_type: "vatType",
  tripletex_currency: "currency",
  tripletex_contact: "contact",
  tripletex_attention: "contact",
}

const NAME_MAP = {
  tripletex_customer: "name",
  tripletex_project_manager: "displayname",
  tripletex_delivery_address: "displayname",
  tripletex_department: "name",
  tripletex_main_project: "name",
  tripletex_vat_type: "displayname",
  tripletex_currency: "displayname",
  tripletex_contact: "displayname",
  tripletex_attention: "displayname",
}

const ENTITY_MAP = {
  tripletex_customer: "customer",
  tripletex_project_manager: "project_manager",
  tripletex_project_category: "project_category",
  tripletex_delivery_address: "address",
  tripletex_department: "department",
  tripletex_main_project: "main_project",
  tripletex_vat_type: "vat_type",
  tripletex_currency: "currency",
  tripletex_contact: "contact",
  tripletex_attention: "attention",
}

export const TripletexResourceField = ({ onChange, ...props }: IFieldProps) => {
  const parsedValue = parseResouceField(props.value)
  const [resources, setResources] = useState<any[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation(["common", "tripletex"])
  const selectedResource = resources.find((e) => e.id === parsedValue?.id)
  const displayName =
    selectedResource?.[NAME_MAP[props.field.type]] ?? "Not defined"
  const entity: string = ENTITY_MAP[props.field.type] ?? ""
  const translatedEntity: string = t(`tripletex:resources.${entity}`)

  useEffect(() => {
    fetchResources()
  }, [])

  const fetchResources = async () => {
    const data = await getTripletexResource(COLLECTION_MAP[props.field.type])
    if (data) {
      setResources(data)
    }
  }

  if (props.readOnly) {
    return <InfoItem title={props?.field?.label} description={displayName} />
  }

  return (
    <div>
      {props?.field?.label && (
        <p className="text-xs font-medium text-gray-500 transition-all duration-300 group-focus-within:text-blue-500">
          {props.field.label}
        </p>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div className="field-style field-input flex w-full cursor-pointer items-center justify-between">
            {selectedResource
              ? displayName
              : (props?.field?.placeholder ??
                t("common:select") + " " + translatedEntity.toLowerCase())}
            <Icon icon="keyboard_arrow_down" className="text-md" />
          </div>
        </PopoverTrigger>
        <PopoverContent className="popover-trigger-width p-0">
          <Command>
            <CommandInput
              className="focus:placeholder:text-gray-400"
              placeholder={`${t("common:search")} ${translatedEntity.toLowerCase()}...`}
            />
            <CommandList>
              <CommandEmpty>{t("common:no_results")}</CommandEmpty>
              <CommandGroup>
                {resources.map((resource) => {
                  const label = resource[NAME_MAP[props.field.type]]
                  return (
                    <CommandItem
                      key={resource.id}
                      value={label}
                      className="flex items-center justify-between"
                      onSelect={() => {
                        onChange({
                          id: resource.id,
                          url: RESOURCE_URL.replace(
                            "{resource}",
                            COLLECTION_MAP[props.field.type],
                          ).replace("{id}", resource.id),
                        })
                        setOpen(false)
                      }}
                    >
                      {label}
                      <Icon
                        icon="check"
                        className={cn(
                          "text-md mr-2",
                          selectedResource?.id === resource.id
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                      />
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export const parseResouceField = (value: any) => {
  if (!value?.id || !value?.url) {
    return null
  }
  return value
}
